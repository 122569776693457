import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import "./team.css";
import team from "./images/team.jpeg"
import Nav2 from "./Nav2.js"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'


function Team() {


  return (<div>
    <Nav2 />
    <div className="team"  id="team">
    <div className="row teamrow">
    <div className="teampic col-lg-6 col-md-6 col-sm-12">
    <h1 className="teamhead">Team</h1>
    <p className="teampara">Our team in St. Louis can help address your unique business problems with robust implementation and support services. Our team can simplify and automate industry-specific processes as per the goals you envision.<br />We provide the right solution for your company, be it recruiting the exact person or team to work directly for you, or outsourcing the entire project to MOKTAMA IT SOLUTIONS. We develop customized solutions that exceed our client’s expectations and we do it on time and on budget.</p>

    </div>
    <div className="teampic col-lg-6 col-md-6 col-sm-12">
    <img className="teamimg" src= {team} />
    </div>

    </div>
    <p className="teampara team-para">
Our team will provide day-to-day operations, as well as leading the company's development and technology strategy. Our team works with client to plan, coordinate and direct all operational activities to organization with in the timeline and budget. Our Team works closely with the executive management team and provides direction for all departments and mission-critical objectives throughout the business Infrastructure.</p>


    </div>
</div>

  );
}

export default Team;
