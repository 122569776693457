import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import "./contact.css";
import contact from "./images/CONTACT.jpg";
import Nav2 from "./Nav2.js"
import { send } from 'emailjs-com';


import '../node_modules/bootstrap/dist/css/bootstrap.min.css'


function Contact() {

  const data = {
    fname: "",
    lname: "",
    email: "",
    msg: "",
    phoneNo: ""
  }
    const [values, setValues] = useState(data);
    const [valid, setValid] = useState(false);

    const fnameinput = (event) => {
      setValues({...values, fname: event.target.value})
    }
    const lnameinput = (event) => {
      setValues({...values, lname: event.target.value})
    }
    const emailinput = (event) => {
      setValues({...values, email: event.target.value})
    }
    const phoneinput = (event) => {
      setValues({...values, phoneNo: event.target.value})
    }
    const msginput = (event) => {
      setValues({...values, msg: event.target.value})
    }
  const [submitted, setSubmitted] = useState(false);

  const submitinput =(event) => {
    event.preventDefault();
    if(values.fname && values.lname && values.email && values.msg && values.phoneNo){
      setValid(true);
    }
    setSubmitted(true)

    send(
      'service_swl80xs',
      'template_w98oyqj',
      values,
      'zVLU5vSBWVcvWwXBT'
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }).catch((err)=> {
      console.log('FAILED...', err);
    })
  }


  return (<div>
    <Nav2 />
    <div className="contact-main">
        <h1 className="contact-head">CONTACT US</h1>
    <div className="contact" id="contact">

    <img className="contactimg" src={contact} />
    <div className="row contact-con">
    <div className="col-lg-3 col-md-3 col-sm-12">
    <h1 className="contact-head1" >Send Us a message</h1>
    </div>
      <div className="col-lg-9 col-md-9 col-sm-12">
      <p clasName="contact-para">If you have any questions or queries a member of staff willl always be happy to help. Feel free to contact us by email and we will be sure to get back to you as soon as possible.</p>
      <form onSubmit={submitinput}>
        {submitted && valid ? <div className="success-message">Message is successfully sent. We will get back to you ASAP</div> : null}
      <input onChange={fnameinput} disabled={submitted}
    value={values.fname} className="first" type="text" placeholder="First Name" />
      {submitted &&  !values.fname ? <span className="warning">Please enter your first name</span> : null}
      <input className="second" type="text" placeholder="Last Name" onChange={lnameinput} disabled={submitted} value={values.lname}/>
        {submitted &&  !values.lname ? <span className="warning">Please enter your Last name</span> : null}
        <input className="email" type="email" placeholder="Email" onChange={emailinput} disabled={submitted} value={values.email}/>
          {submitted &&  !values.email ? <span className="warning">Please enter your Email Id</span> : null}
          <input className="email" type="number" placeholder="Phone Number" onChange={phoneinput} disabled={submitted} value={values.phoneNo}/>
            {submitted &&  !values.phoneNo ? <span className="warning">Please enter your Phone Number</span> : null}
        <input className="message" type="text" placeholder="Type your message" onChange={msginput} disabled={submitted} value={values.msg}/>
          {submitted &&  !values.msg ? <span className="warning">Please enter the message</span> : null}
        <button className="contact-button">Submit</button>
      </form>

</div>
</div>
</div>
<div className="find">
<h1 className="find-head">FIND US</h1>
  <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3114.520900759551!2d-90.40693908532548!3d38.6828773796043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87df331ac4dfdd75%3A0xa290433d7b3f75a7!2s10314%20Briar%20Hollow%20Dr%2C%20St.%20Louis%2C%20MO%2063146%2C%20USA!5e0!3m2!1sen!2sin!4v1675598449108!5m2!1sen!2sin"  ></iframe>
</div>
</div>
</div>
  );
}

export default Contact;
