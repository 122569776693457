import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import "./serve.css";
import serve1 from "./images/serve1.jpg"
import serve2 from "./images/serve.gif"
import client from "./images/clients.png"

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'


function Serve() {


  return (
    <div className="serve">
   <h1 className="serve-ind"> INDUSTRIES WE SERVE </h1>
   <p className="serve-para">MOKTAMA IT SOLUTIONS provides cost effective solutions because we have consultants in almost every industry</p>
    <img className="serveimg" src={serve1}/>
      <img className="serveimg" src={serve2}/>
      <div className="serveimg3">
      <h3 className="clienthead">How we help our Clients ?</h3>
      <img className="clientimg" src={client}/>
      </div>


    </div>


  );
}

export default Serve;
