import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from "./navbar.js";
import About from "./about.js";
import Team from "./team.js";
import Serve from "./serve.js";
import Contact from "./contact.js"
import apt from "./images/apt.jpg"
import prot from "./images/protos.jpg"
import experis from "./images/experis.jpg"
import "./App.css";


function Home() {
  return (
    <div className="home" id="home">
   <Navbar />
     <p className="home-para">MOKTAMA IT Solutions will bring together the right people, knowledge, methodology, and technologies you require for the project at hand.<br/><br />Moktama provides honest and quality consulting IT services in today's dynamic environment. We seek to empower clients with a complete range of quality-oriented services, robust solutions, and people that keep their organizations from falling behind the IT innovation curve. Our team is ready to help you get work done quickly and efficiently. We get what you are dealing with and get your staffing requirements met in a fast, economical and reliable manner.</p>
   <div className="aboutsec">
   <h1 className="abouthead">Partners</h1>
   <p className="aboutpara1">We look forward in working with companies across the globe to build joint ventures and develop solutions depending on their needs and meet their business requirements as their business grows. Majorly we are partnered with product development companies in the areas of Business Intelligence, Business Analytics, Networking and cloud service providers. Some of our technical partners we are partnered with</p>
   <div className="row">
   <div className="col-4 wrapper">
   <img className="partimg slide" src={apt} />
   </div>
   <div className="col-4">
   <img className="partimg" src={experis} />
   </div>
   <div className="col-4">
   <img className="partimg" src={prot} />
   </div>
   </div>
   </div>

   <Serve />
   <div className="acquire">
   <h1 className="acquirehead">WHETHER YOU REQUIRE</h1>
   <div className="row acquire-con">
   <div className="col-lg-4 col-md-6 col-sm-12 ">
   <div className= "acquire-item">
   <span className="acquirepara">Staff Augmentation</span>
   </div>
   </div>
   <div className="col-lg-4 col-md-6 col-sm-12 ">
   <div className= "acquire-item">
   <span className="acquirepara ">Strategy Consulting</span>
   </div>
   </div>
   <div className="col-lg-4 col-md-6 col-sm-12">
   <div className= "acquire-item">
   <span className="acquirepara ">Technology Integration</span>
   </div>
   </div>
   </div>
   <div className="row">
   <div className="col-lg-6 col-md-6 col-sm-12">
   <div className= "acquire-item">
   <span className="acquirepara ">Offsite,onsite or fixed bid projects</span>
   </div>
   </div>
   <div className="col-lg-6 col-md-6 col-sm-12">
   <div className= "acquire-item">
   <span className="acquirepara ">Offshore development solutions</span>
   </div>
   </div>
   </div>
   <h3 className="acquire-foot">MOKTAMA IT Solutions can get the job done.</h3>
   </div>

    </div>
  );
}

export default Home;
