import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import "./navbar.css";
import Logo from "./images/logo.png"


function Navbar() {



  return (
    <div className="navbar2 topnav">

  <nav className="nav2 dropdown-content">
  <Link to="/">  <img className="logo" src={Logo} /></Link>
  <ul className="ul">
  <li> <Link to="/"><a className="navlink2" href="">Home</a></Link></li>
      <li>   <Link to="/team"><a className="navlink2" href="">Team</a></Link></li>
        <li>    <Link to="/about"><a className="navlink2" href="">About</a></Link></li>
        <li>    <Link to="/contact"><a className="navlink2" href="">Contact</a></Link></li>

</ul>
    </nav>

   <div className="intro">
   <h1 className="introhead">MOKTAMA IT SOLUTIONS</h1>
   </div>
    </div>
  );
}

export default Navbar;
