import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./home.js"
import "./App.css";
import Team from "./team.js";
import Contact from "./contact.js";
import About from "./about.js"


function App() {
  return (
    <div className="">
  <Router>
    <Routes>
    <Route path="/" element={<Home />}></Route>
    <Route path="/team" element={<Team />}></Route>
    <Route path="/about" element={<About />}></Route>
    <Route path="/contact" element={<Contact/>}></Route>
    </Routes>
   </Router>
    </div>
  );
}

export default App;
