import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, { useEffect, useRef, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import "./Nav2.css";
import Logo from "./images/logo.png"




function Nav2() {



  return (
    <div className="navbar3 topnav">

  <nav className="nav2 dropdown-content">
  <Link to="/">  <img className="logo" src={Logo} /></Link>
  <ul className="ul">
  <li> <Link to="/"><a className="navlink2" href="">Home</a></Link></li>
      <li>   <Link to="/team"><a className="navlink2" href="">Team</a></Link></li>
        <li>    <Link to="/about"><a className="navlink2" href="">About</a></Link></li>
        <li>    <Link to="/contact"><a className="navlink2" href="">Contact</a></Link></li>

</ul>
    </nav>

    </div>
  );
}

export default Nav2;
