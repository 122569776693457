import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import "./about.css";
import exp from "./images/exp.png"
import part from "./images/part.png"
import ind from "./images/ind.png";
import Nav2 from "./Nav2.js"

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'


function About() {



  return (<div>
    <Nav2 />
    <div className="about" id="about">
    <h3 className="abouthead">About Us</h3>
    <p className="aboutpara1">MOKTAMA IT SOLUTIONS LLC as Leader in IT industry can help you to discover where you are now and where you need to be to achieve measurable results.</p>
  
    <p className="aboutpara1">MOKTAMA IT SOLUTIONS is one of the fastest-growing IT Staffing companies today, based in St' Louis, Missouri. We will help you choose the right career path, give you training in onsite lab facilities, and hands-on experience at one of our client locations, which span the USA. We are here to support your unique career goals and provide counseling and guidance to keep you on the right track. We provide training in multiple technologies, résumé preparation, and prepare you for interview and placement.</p>
    <div className="aboutcontent">
    <div className="row">
    <div className="content1 col-lg-4 col-md-6 col-sm-12">
    <h1 className="conthead">We Value Human expertise</h1>
    <img src={exp} />
    </div>
    <div className="content1 col-lg-4 col-md-6 col-sm-12">
    <h1 className="conthead">We establish partnership</h1>
    <img src={part} />
    </div>
    <div className="content1 col-lg-4 col-md-6 col-sm-12">
    <h1 className="conthead">We cover all major industries</h1>
    <img src={ind} />
    </div>
    </div>
        </div>
</div>
    </div>


  );
}

export default About;
